<template>
    <div class="user" v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 255, 255, 0.8)">
        <div class="teb_header">
            <div class="hang">  
                <div style=" display: flex;">
                <el-input v-model="cname" placeholder="用户名搜索"  class="ss"  :size="tabsize"   ></el-input>
                <el-button icon="el-icon-search" type="success" :size="tabsize" @click="list()">搜索</el-button>
                </div>
                <el-button :size="tabsize" type="primary" @click="pldr()" style="margin-left:10px">批量导入</el-button>
            </div>
        </div>
       <div class="tbl">
        <el-table
            ref="multipleTable"
            border
            height="76vh"
            :size="tabsize"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%; height: calc(100% - 90px);"
            @selection-change="handleSelectionChange"
            >
            <el-table-column type="selection" width="55" ></el-table-column>
            <el-table-column label="时间" prop="username" align="center" width="150">
                <template slot-scope="scope">
                    <span>{{ formatDate(scope.row.checktime )}}</span>
                </template>
            </el-table-column>
            <el-table-column label="家长" prop="pname" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.pname=="" ? "/" :scope.row.pname }}</span>
                </template>
            </el-table-column>
            <el-table-column label="手机号" prop="phone" align="center" width="150">
                <template slot-scope="scope">
                    <span>{{ scope.row.phone == null  ? "/" :scope.row.phone   == ""?"/" :scope.row.phone}}</span>
                </template>
            </el-table-column>
            <el-table-column label="姓名" prop="cname" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.cname==""||null ? "/" :scope.row.cname }}</span>
                </template>
            </el-table-column>
            <el-table-column label="身份证号" prop="szfpnum" align="center" width="160">
                <template slot-scope="scope">
                    <span>{{ scope.row.card | apkUpdate}}</span>
                </template>
            </el-table-column>
            <el-table-column label="年龄" prop="age" align="center">
                <template slot-scope="scope">
                    <span>{{ formatAge(scope.row.age) | apkUpdate  }}</span>
                </template>
            </el-table-column>
            <el-table-column label="性别" prop="sex" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.sex | sex }}</span>
                </template>
            </el-table-column>
            <el-table-column label="身高" prop="height" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.height | apkUpdate}}</span>
                </template>
            </el-table-column>
            <el-table-column label="体重" prop="weight" align="center">
                <template slot-scope="scope">
                     <span>{{ scope.row.weight | apkUpdate}}</span>
                </template>
            </el-table-column>
            <el-table-column label="裸眼R" prop="rev" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.rev | apkUpdate}}</span>
                </template>
            </el-table-column>
            <el-table-column label="裸眼L" prop="lev" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.lev| apkUpdate}}</span>
                </template>
            </el-table-column>
            <el-table-column label="矫正R" prop="rcv" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.rcv | apkUpdate}}</span>
                </template>
            </el-table-column>
            <el-table-column label="矫正L" prop="lcv" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.lcv| apkUpdate }}</span>
                </template>
            </el-table-column>
            <el-table-column label="小瞳球镜R" prop="rs" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.rs  |sign}}</span>
                </template>
            </el-table-column>
            <el-table-column label="小瞳球镜L" prop="ls" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.ls |sign }}</span>
                </template>
            </el-table-column>
            <el-table-column label="小瞳柱镜R" prop="rc" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.rc|sign }}</span>
                </template>
            </el-table-column>
            <el-table-column label="小瞳柱镜L" prop="lc" align="center">
                <template slot-scope="scope">
                     <span>{{ scope.row.lc | sign}}</span>
                </template>
            </el-table-column>
            <el-table-column label="R眼轴" prop="ra" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.ra | apkUpdate}}</span>
                </template>
            </el-table-column>
            <el-table-column label="L眼轴" prop="la" align="center">
                <template slot-scope="scope">
                     <span>{{ scope.row.la| apkUpdate }}</span>
                </template>
            </el-table-column>
            <el-table-column label="RK1" prop="rk1" align="center">
                <template slot-scope="scope">
                       <span>{{ scope.row.rk1 | apkUpdate}}</span>
                </template>
            </el-table-column>
            <el-table-column label="RK2" prop="rk2" align="center">
                <template slot-scope="scope">
                      <span>{{ scope.row.rk2 | apkUpdate}}</span>
                </template>
            </el-table-column>
            <el-table-column label="LK1" prop="lk1" align="center">
                <template slot-scope="scope">
                       <span>{{ scope.row.lk1| apkUpdate }}</span>
                </template>
            </el-table-column>
            <el-table-column label="LK2" prop="lk2" align="center">
                <template slot-scope="scope">
                       <span>{{ scope.row.lk2 | apkUpdate}}</span>
                </template>
            </el-table-column>
            <el-table-column label="SLS" prop="sls" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.sls| sign }}</span>
                </template>
            </el-table-column>
            <el-table-column label="SRS" prop="srs" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.srs | sign}}</span>
                </template>
            </el-table-column>
            <el-table-column label="SLC" prop="slc" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.slc| sign }}</span>
                </template>
            </el-table-column>
            <el-table-column label="SRC" prop="src" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.src | sign}}</span>
                </template>
            </el-table-column>
            <el-table-column label="LAY" prop="lay" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.lay| sign }}</span>
                </template>
            </el-table-column>
            <el-table-column label="RAY" prop="ray" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.ray | sign}}</span>
                </template>
            </el-table-column>
            <el-table-column label="RAX" prop="rax" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.rax| apkUpdate}}</span>
                </template>
            </el-table-column>
            <el-table-column label="LAX" prop="lax" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.lax| apkUpdate}}</span>
                </template>
            </el-table-column>
            <el-table-column label="R眼压" prop="ri" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.ri | apkUpdate2}}</span>
                </template>
            </el-table-column>
            <el-table-column label="L眼压" prop="li" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.li| apkUpdate2 }}</span>
                </template>
            </el-table-column>
            <el-table-column label="PD" prop="pd" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.pd | apkUpdate}}</span>
                </template>
            </el-table-column>
            <el-table-column label="同时视" prop="tss" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.tss | apkUpdate}}</span>
                </template>
            </el-table-column>
            <el-table-column label="融合视" prop="rhs" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.rhs| apkUpdate }}</span>
                </template>
            </el-table-column>
            <el-table-column label="立体视" prop="lts" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.lts | apkUpdate}}</span>
                </template>
            </el-table-column>
            <el-table-column label="远用眼位" prop="yyyw" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.yyyw | apkUpdate}}</span>
                </template>
            </el-table-column>
            <el-table-column label="调节反应" prop="tjfy" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.tjfy | apkUpdate}}</span>
                </template>
            </el-table-column>
            <el-table-column label="近用眼位" prop="jyyw" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.jyyw | apkUpdate}}</span>
                </template>
            </el-table-column>
            <el-table-column label="AC/A" prop="jtxac" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.jtxac | apkUpdate}}</span>
                </template>
            </el-table-column>
            <el-table-column label="NRA" prop="nra" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.nra| apkUpdate }}</span>
                </template>
            </el-table-column>
            <el-table-column label="PRA" prop="pra" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.pra| apkUpdate }}</span>
                </template>
            </el-table-column>
            <el-table-column label="右眼AMP" prop="ramp" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.ramp | apkUpdate}}</span>
                </template>
            </el-table-column>
            <el-table-column label="左眼AMP" prop="lamp" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.lamp| apkUpdate }}</span>
                </template>
            </el-table-column>
            <el-table-column label="双眼AMP" prop="samp" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.samp| apkUpdate }}</span>
                </template>
            </el-table-column>
            <el-table-column label="右眼Flippers" prop="rzfpnum" align="center" width="100">
                <template slot-scope="scope">
                    <span>{{ scope.row.rzfpnum | apkUpdate}}</span>
                </template>
            </el-table-column>
            <el-table-column label="左眼Flippers" prop="lzfpnum" align="center" width="100">
                <template slot-scope="scope">
                    <span>{{ scope.row.lzfpnum | apkUpdate}}</span>
                </template>
            </el-table-column>
            <el-table-column label="双眼Flippers" prop="szfpnum" align="center" width="100">
                <template slot-scope="scope">
                    <span>{{ scope.row.szfpnum | apkUpdate}}</span>
                </template>
            </el-table-column>
            <el-table-column label="右眼正负片" prop="rzfp" align="center" width="100">
                <template slot-scope="scope">
                    <span>{{ scope.row.rzfp | apkUpdate}}</span>
                </template>
            </el-table-column>
                <el-table-column label="左眼正负片" prop="lzfp" align="center" width="100">
                <template slot-scope="scope">
                    <span>{{ scope.row.lzfp | apkUpdate}}</span>
                </template>
            </el-table-column>
            <el-table-column label="双眼正负片" prop="szfp" align="center" width="100">
                <template slot-scope="scope">
                    <span>{{ scope.row.szfp | apkUpdate}}</span>
                </template>
            </el-table-column>
            <el-table-column label="状态" prop="kislogs" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.kislogs | apkUpdate}}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="220">
                <template slot-scope="scope">
                    <el-button :size="tabsize" icon="el-icon-view"  @click.native.prevent="handlelook(scope.$index,  scope.row)">查看
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        </div>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
            :total="tableData.length"></el-pagination>
    </div>
</template>

<script>
import dayjs from 'dayjs';
    export default {
        data() {
            return {
                cname:"",
                pname: '', // 搜索内容
                phone: '', // 搜索内容
                tableData: [],
                sort: "id",
                order: "desc",
                currentPage: 1, //默认显示第一页
                pageSize: 10, //默认每页显示10条
                totalNum: "", //总页数
                ids: [],
                loading: false,
                order: '',
                sort: '',
            }
        },


        computed: {
                tabsize() {
                      this.$setTableHeight();
                          return this.$store.state.tabsize;
                },
            },


        methods: {
               formatDate(val) {
                return val ? dayjs(val).format("YYYY-MM-DD") : ''
            },

            handlelook(index, row) {
               console.info(row.id);
                this.$router.push({
                    path: "./share",
                    query: { kiid: row.id },
                });
            },
            pldr(){
                this.$router.push({
                    path: '/pldr',
                })
            },
            list() {
                this.loading = true;
                setTimeout(() => {
                   this.loading = false
                }, 600000);
                this.$api.uuser.list({
                    'jPage.pageNum': this.currentPage, //当前页码
                    'jPage.pageSize': this.pageSize, //当前页面大小
                    'jPage.sort': this.sort, //排序方式
                    'jPage.order': this.order, //排序字段
                    "cname":this.cname,
                }).then(res => {
                    this.tableData = res.obj.list //获取数据列表
                    this.totalNum = res.obj.totalPage // 总页数
                    this.tableData.length = res.obj.totalRow //总条数 
                    this.currentPage = res.obj.pageNumber //当前页码数
                    this.loading = false
                    console.log("tableData=>",this.tableData);
                }).catch((error) => {
                    console.log(error);
                })
            },

            handleSelectionChange(val) {
                        this.multipleSelection = val;
            },

            formatAge(val) {
                return val ? Math.floor(val / 12) + '岁' + ((val % 12) != 0 ? (val % 12) + '个月' : '') : ''
            },

            // 分页大小
            handleSizeChange(val) {
                this.loading = true
                this.pageSize = val
                this.currentPage = 1 // 选择分页大小后跳转第一页
                this.list()
            },
            // 第几页
            handleCurrentChange(val) {
                this.loading = true
                this.currentPage = val
                this.list()
            },
            // 排序
            sortchange(val) {
                this.sort = val.prop
                if (val.order === 'descending') {
                    this.order = 'desc'
                } else if (val.order === 'ascending') {
                    this.order = 'asc'
                } else {
                    this.order = ''
                    this.sort = ''
                }
                this.loading = true
                this.list();
            },
        },

        

        created() {
            this.list()
        },
    }
</script>

<style scoped>

    .user {
        position: relative;
        width: 98%;
        height: 100%;
        margin: 1%;
    }

    .teb_header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .teb_header .el-input {
        width: auto;
        margin-left: 20px;
    }

    .el-table td {
        padding: 5px 0;
    }

    .el-pagination {
        float: right;
        margin-top: 10px;
    }


    .hang {
  display: flex;
  align-items:center;
}

    .ss {
  margin-right: 2vw;
  width: 12.2vw !important;
}
.tbl{
       min-height: 75vh;
    overflow-y: auto;
}





</style>